<template>
  <div>
    <div>
      <el-row class="bgW">
      <el-col :span="20">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="门店类型" prop="type">
            <el-select
              v-model="search.type"
              class="w100"
              clearable
              placeholder="门店类型"
            >
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="4" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>

      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="tableData"
            size="small"
			max-height="750"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="cellStyle"
            :header-cell-style="rowClass"
          >
            <ElTableColumn label="市场" prop="market_name" label-class-name="market"/>
            <ElTableColumn label="店面" prop="shop_name" />

            <ElTableColumn label="线上进店">
              <el-table-column v-for="(item, index) in online" :key="index"  :label="item.name" >
                 <template slot-scope="{ row }">
                  <div class="hand" @click="clickOnline(row,row.online[index].id)">{{row.online[index].num}}</div>
                </template>
              </el-table-column>
            </ElTableColumn>
            <ElTableColumn label="线下进店">
              <el-table-column v-for="(item, index) in offline" :key="index"  :label="item.name">
                 <template slot-scope="{ row }">
                  <div  >{{row.offline[index].num}}</div>
                </template>
              </el-table-column>
            </ElTableColumn>

            <ElTableColumn label="其他">
              <el-table-column v-for="(item, index) in rests" :key="index"  :label="item.name">
                 <template slot-scope="{ row }">
                  <div  >{{row.qita[index].num}}</div>
                </template>
              </el-table-column>
            </ElTableColumn>

            <ElTableColumn label="总进店数量" prop="all" />
            <ElTableColumn label="档案数量" prop="daall" />
            <ElTableColumn label="网络进店率" prop="wljoin" />
          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getLastDay } from '@/utils';
import pagination from "@/components/Pagination";
import { shopDk } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  type = "";
}
class Options {
  type = [
      {
        id: "1",
        name: "直营"
      },
      {
        id: "2",
        name: "加盟"
      },
      {
        id: "3",
        name: "直管"
      }
    ];
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      options :new Options(),
      list: [], // 数据展示
      online: [], //线上进店
      offline: [], //线下进店
      rests:[], //其他
      loading:false,
      exportS: false,
    };
  },
  computed: {
      tableData() {
        const res = this.list.reduce((res, item) => {
          item.maxnum = 0;
          if (item.list && item.list.length > 0) {
            const goods = item.list.map(goods => Object.assign({}, item, goods));
            goods[0].maxnum = item.list.length;
            res = res.concat(goods);
          } else {
            res.push(item);
          }
          return res;
        }, []);
        return res;
      }
    },
  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');

  },
  methods: {
    // 获取列表
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      shopDk({ ...this.search,export:0}).then(res => {
        console.log(res)
        this.list = res.data.list;
        this.online = res.data.title.online;
        this.offline = res.data.title.offline;
        this.rests  = res.data.title.qita;
        this.loading = false
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 1){
            return 'background:#CCFFFF;text-align:center'
        }
        if(columnIndex > 1 && columnIndex <= 13){
            return 'background:#FDE9D9;text-align:center'
        }
        if(columnIndex >= 14 && columnIndex <= 19){
            return 'background:#D8E4BC;text-align:center'
        }
        if(columnIndex >= 20){
            return 'background:#FFFF99;text-align:center'
        }
        // if(columnIndex = 21){
        //     return 'background:#FFFF99;text-align:center'
        // }
        // if(columnIndex = 22){
        //     return 'background:#FFFF99;text-align:center'
        // }
        return '';
      },

      rowClass({row, column, rowIndex, columnIndex}){
        return 'background:#E9EDEE;text-align:center'

      },
    // 导出
    exportExcel() {
      this.exportS = true
      shopDk({...this.search,export:1}).then(res => {
        window.open(res.data.url);
        this.exportS = false
      });
    },
    // 跳转事件
    clickOnline(row,id){
      this.$router.push("./entranceShop?shop_id="+row.shop_id + '&source_id='+id  + '&start_time='+this.search.start_time + '&end_time='+this.search.end_time);
    },
  }
};
</script>

<style lang="scss" scoped>
.hand{
  cursor:pointer;
}
.btnBox{
  text-align: right;
}
.market{
  background: red;
}
</style>
